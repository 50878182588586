import dynamic from 'next/dynamic'
import Head from 'next/head'
import {
  getFeatureDisabledElements,
  getFeatureDisabledRoutes,
  getRoutesForProgressPage,
} from 'utils/next/routes'
import { LayoutProvider } from 'providers/LayoutProvider'
import { HeaderProvider } from 'providers/HeaderProvider'
import styles from 'components/Layout/Layout.module.scss'
import { useRouter } from 'next/router'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
const Alchemer = dynamic(() => import('components/Alchemer'))
import { useState } from 'react'
const FFAMBanner = dynamic(() => import('components/FFAMBanner'))
const HeaderContainer = dynamic(() => import('components/Header/HeaderContainer/HeaderContainer'))
import { getTwitterImage, getOgTypeMetaTag, getPageMetaTitleForRoute } from 'utils/metadata'
const FooterContainer = dynamic(() => import('components/Footer/FooterContainer/FooterContainer'))
const SiteWideBanner = dynamic(() => import('components/SiteWideBanner'))
import { GlobalProvider } from 'providers/GlobalProvider'
import ApolloClientProvider from 'providers/ApolloClientProvider/ApolloClientProvider'
import { ConsentBanner } from 'components/ConsentBanner'

import { FEATURE_TOGGLES } from 'constants/featureToggles'
import { isFFAMDisabled } from 'constants/ffam'
import { PRD_ENV } from 'constants/environments'
import { mainProductConfig } from 'services/contentful/helpers'
const FooterSticky = dynamic(() => import('components/Footer/FooterSticky/FooterSticky'))
import classNames from 'classnames'
import { ultramarine } from 'components/Fonts/Fonts'
import { isStandaloneRoute } from 'utils/validations'

export default function Layout({
  canonicalLink,
  children,
  emergencyBanners,
  featureToggleObject,
  ffamReferrerContent,
  footer,
  header,
  isFFAMReferrer,
  isMobileUA,
  metaDescription,
  pageConfig,
  pageDisclosure = '',
  pageMetaData = [],
  pageMetaTitle,
  pageTitle,
  publicProcessDotEnv,
  secondaryDisclosures = [],
  showFooter = true,
  showHeader = true,
  alignContentBottom = false,
  consentBanner,
  specificHeaderCtaDataFiltered,
  footerSticky,
  showPhoneNumber,
  siteInfo,
}) {
  // Consider it a lower environment if we have an app env being passed and it's not set as
  // `production`. If it matches production or no env was passed, do not block bots
  const appEnv = publicProcessDotEnv?.NEXT_PUBLIC_APP_ENV
  const isLowerEnv = appEnv ? appEnv !== PRD_ENV : false

  const disabledRoutes = getFeatureDisabledRoutes(featureToggleObject)
  const disabledElements = getFeatureDisabledElements(featureToggleObject)

  const usesSiteWideBanner = useFeatureFlag(FEATURE_TOGGLES.ACX_WEB_ENABLE_SITE_WIDE_BANNER)

  const [closeFFAMBanner, setCloseFFAMBanner] = useState(false)

  const router = useRouter()
  const routePath = router.asPath
  const parentPath = routePath
    ? routePath.split('/')[2] === 'blog'
      ? 'blog'
      : routePath.split('/')[1]
    : ''
  const hasUTM = routePath ? routePath.includes('utm_') : false

  const showProgressPage = getRoutesForProgressPage.includes(router.pathname)

  /**
   * The object below is used by home-equity-loan-mp to display phone number in the header
   * based on utm_source and utm_content. Phone number data and expected utms are stored
   * in the pageConfig object in the Home Equity Loan MP contentful entry.
   * Implemented by PXP team.
   * Contact: Shreyas Dorle, Brandon Chapman
   */
  let customPhoneData
  if (pageConfig?.customPhoneData) {
    customPhoneData = {
      utm_source: router?.query?.utm_source ?? null,
      utm_content: router?.query?.utm_content ?? null,
      ...pageConfig.customPhoneData,
    }
  }

  const dynamicPageMetaTitle = getPageMetaTitleForRoute(router, pageMetaTitle, pageTitle)
  const image = getTwitterImage(parentPath, children)

  const handleCloseDialog = () => {
    setCloseFFAMBanner(true)
  }

  const defaultPageMetaTitle = mainProductConfig[header?.siteEnv || 'achieve']?.['MainName']

  const titlePage = dynamicPageMetaTitle
    ? `${dynamicPageMetaTitle} ${router.pathname === '/' ? '' : `| ${defaultPageMetaTitle}`}`
    : defaultPageMetaTitle
  const renderEmergencyBanners = () => {
    const list = []
    for (let i = 0; i < emergencyBanners.length; i++) {
      const element = emergencyBanners[i]
      list.push(
        <SiteWideBanner
          title={element?.fields?.title}
          message={element?.fields?.message}
          handleCloseDialog={() => {}}
        />
      )
    }
    return list
  }

  const webpageSchema = {
    '@context': 'https://schema.org/',
    '@type': 'WebPage',
    name: titlePage,
    url: `${canonicalLink}/`,
    description: metaDescription,
    inLanguage: 'en-US',
  }

  return (
    <div className={styles['page-container']} page-main-variation={header?.siteEnv}>
      <Head data-testid="layout-head">
        <title data-testid="layout-title">{titlePage}</title>
        <link rel="canonical" data-testid="layout-head-canonical-link" href={`${canonicalLink}`} />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta charSet="UTF-8" />
        {
          /* Add No follow for pages with utm parameters */
          (isLowerEnv || hasUTM) && <meta name="robots" content="noindex, nofollow" />
        }
        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@achievecom" />
        <meta name="twitter:title" content={`${dynamicPageMetaTitle}`} />
        {metaDescription && <meta name="twitter:description" content={`${metaDescription}`} />}
        <meta name="twitter:image" content={image} />
        {/* Facebook */}
        <meta name="og:image" content={image} />
        <meta name="og:locale" content="United States" />
        <meta name="og:title" content={`${dynamicPageMetaTitle}`} />
        <meta name="og:url" content={`${canonicalLink}`} />
        <meta
          name="og:site_name"
          content={`${mainProductConfig[header?.siteEnv || 'achieve'].MainName}`}
        />
        {getOgTypeMetaTag(parentPath)}
        {metaDescription && <meta name="og:description" content={`${metaDescription}`} />}
        {/*Meta data description tag*/}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {/*Meta data other tags*/}
        {pageMetaData
          .filter(({ fields: { name } }) => name !== 'viewport')
          .map(({ fields: { name, content } }) => (
            <meta key={name} name={name} content={content} />
          ))}
      </Head>

      <ApolloClientProvider>
        <LayoutProvider isMobileUA={isMobileUA} siteEnv={header?.siteEnv} siteInfo={siteInfo}>
          <GlobalProvider pageDisclosure={pageDisclosure || ''}>
            <div style={{ position: 'relative' }}>
              {showHeader && (
                <HeaderProvider>
                  <HeaderContainer
                    content={header}
                    disabledRoutes={disabledRoutes}
                    disabledElements={disabledElements}
                    customPhoneData={customPhoneData ?? null}
                    showProgressPage={showProgressPage}
                    specificHeaderCtaDataFiltered={specificHeaderCtaDataFiltered}
                    footerSticky={footerSticky}
                    showPhoneNumber={showPhoneNumber}
                  />
                  {!isStandaloneRoute(router.pathname) && (
                    <ConsentBanner
                      contentfulId={consentBanner?.sys?.id}
                      contentfulVersion={consentBanner?.sys?.revision}
                    />
                  )}
                </HeaderProvider>
              )}

              <div
                className={classNames(styles['max-width-container'], 'group')}
                data-narrow={true}
                data-align-content-bottom={alignContentBottom}
              >
                <main
                  className={classNames(styles.main, ultramarine.variable, 'font-ultramarine')}
                  id="main-layout"
                  data-no-header={!showHeader}
                  data-show-progress={showProgressPage}
                >
                  {!closeFFAMBanner && !isFFAMDisabled() && usesSiteWideBanner && (
                    <FFAMBanner
                      isFFAMReferrer={isFFAMReferrer}
                      ffamReferrerContent={ffamReferrerContent}
                      handleCloseDialog={handleCloseDialog}
                    />
                  )}
                  {emergencyBanners?.length > 0 && renderEmergencyBanners()}
                  {children}
                </main>
              </div>
            </div>

            <FooterSticky
              specificHeaderCtaDataFiltered={specificHeaderCtaDataFiltered}
              headerContent={header}
              content={footerSticky}
            />

            {showFooter && (
              <FooterContainer
                disabledRoutes={disabledRoutes}
                content={footer}
                secondaryDisclosures={secondaryDisclosures}
                data-testid="footer"
                showNav={header?.showNavFooter}
                showFooterSocialIcons={header?.showFooterSocialIcons}
                hiddenNavSection={pageConfig?.hiddenNavSection}
              />
            )}
          </GlobalProvider>
        </LayoutProvider>
      </ApolloClientProvider>
      {/* Alchemer survey script */}
      {disabledElements[FEATURE_TOGGLES.ACX_WEB_ENABLE_ALCHEMER_SURVEY] && <Alchemer />}
      <script
        id="webPage"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(webpageSchema) }}
      />
    </div>
  )
}
